import styled, { css } from 'styled-components'

const StyledConditionItem = styled.div`
    margin-top: 24px;
    width: 100%;
    border-bottom: 4px solid #4F324F;
    position: relative;
    overflow: hidden;
    transition: 500ms;
    height: ${({heightTxt}) => `${heightTxt}px`};
    cursor: pointer;

        ${(props) => props.pageProduct === true && css`
            width: 48.78993224%;
            border-bottom: 4px solid #F4E1F4;
                h5 {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal !important;
                    font-size: 24px !important;
                    line-height: 29px !important;
                    width: 100%;
                    text-align: center;
                    color: #000000;
                }
                p {
                    font-size: 18px !important;
                    line-height: 22px !important;
                }
        `}

        h5 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
        }

        p {
            padding: 24px 0;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
        ul {
            padding: 24px 0;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
        li {
            padding: 12px 0;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }

        svg {
            bottom: 10px;
            right: 0px;
            position: absolute;
            cursor: pointer;
        }
`

export default StyledConditionItem