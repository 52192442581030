import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProduct } from '../components/PageProduct/PageProduct';

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.datoCmsProduct
  const moreProducts = data.moreProducts
  const siteTitle = data.site.siteMetadata.title
  // const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.titre}
        description={post.descriptionNode.childMarkdownRemark.html.excerpt}
      />
      <PageProduct product={post} title={post.titre} moreProducts={moreProducts}/>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    datoCmsProduct(originalId: {eq: $slug}) {
          categorie
          taxe
          contenance
          montantDeLaRemise
          nombreDUnitDansCeLot
          originalId
          prix
          prixDuLotAvecRemise
          ceProduitExisteEnLot
          sousTitre
          titre
          image {
            fixed(height: 400) {
              src
            }
          }
          descriptionNode {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 100)
            }
          }
          instructionsNode {
            childMarkdownRemark {
              html
            }
          }
          ingredientsNode {
            childMarkdownRemark {
              html
            }
          }
          conservationNode {
            childMarkdownRemark {
              html
            }
          }
    }
    moreProducts:allDatoCmsProduct(limit: 4, filter: {originalId: {ne: $slug}}) {
      edges {
        node {
          categorie
          contenance
          montantDeLaRemise
          nombreDUnitDansCeLot
          originalId
          prix
          prixDuLotAvecRemise
          ceProduitExisteEnLot
          sousTitre
          titre
          image {
            fixed(height: 240) {
              src
              ...GatsbyDatoCmsFixed
            }
          }
          descriptionNode {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 100)
            }
          }
        }
      }
    }
  }
`


