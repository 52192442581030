import React, { useState, useRef, useEffect } from 'react'
import StyledConditionItem from './StyledConditionItem';

export const ConditionItem = ({ title, desc, id, pageProduct, openDesc, openInstr, setOpenDesc, setOpenInstr }) => {
    const [isOpenTabs, setIsOpenTabs] = useState(false)
    const [heightTxt, setHeightTxt] = useState(0)

    let txt = useRef(null)

    const toggleTabs = () => {
        setIsOpenTabs(!isOpenTabs)
        if (openDesc !== undefined) {
            setOpenDesc(!isOpenTabs)
        }
        if (openInstr !== undefined) {
            setOpenInstr(!isOpenTabs)
        }
    }

    useEffect(() => {
        if (openDesc === true) {
            setIsOpenTabs(openDesc)
        }
    }, [openDesc])

    useEffect(() => {
        if (openInstr === true) {
            setIsOpenTabs(openInstr)
        }
    }, [openInstr])

    useEffect(() => {
        if (isOpenTabs === false ) {
            setHeightTxt(48) 
        } 
        if (isOpenTabs === true) {
            setHeightTxt(txt.current.clientHeight + 48)
        }
    }, [isOpenTabs])


    return (
        <StyledConditionItem isOpenTabs={isOpenTabs} heightTxt={heightTxt} onClick={toggleTabs} pageProduct={pageProduct}>
            <h5>{id ? id + '. ' : null}{title}</h5>
            {pageProduct === true || id === 4 || id === 10 ?
                <div ref={txt} dangerouslySetInnerHTML={{ __html: desc }} />
                : 
                <p ref={txt}>{desc}</p>
            }
            {!isOpenTabs ?
                <svg onClick={toggleTabs} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999999 1L9 13L17 1" stroke="#4F324F"/>
                </svg>
            :
                <svg onClick={toggleTabs} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 13L9.25 1L1 13" stroke="#4F324F"/>
                </svg>
            }
        </StyledConditionItem>
    )
}
