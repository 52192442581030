import styled, { css } from 'styled-components'

const StyledPageProduct = styled.section`
    width: 1296px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

        ${(props) => props.onlyOneTab === true && css`
            .container-details div {
                width: 100% !important;
            }
        `}

        @media screen and (max-width: 1420px) {
            width: 100%;
            .wrapper-more-products {
                width: 96% !important;
                grid-template-columns: repeat(2, 328px) !important;
                justify-content: center !important;
            }
            .wrapper-titleh3 {
                padding-left: 3vw !important;
            }
            .wrapper-price-quantity {
                justify-content: space-between;
            }
        }
        @media screen and (max-width: 1300px) {
            .container-ficheproduit {
                width: 96% !important;
            }
        }
        @media screen and (max-width: 1090px) {
            .wrapper-price-quantity .snipcart-add-item  {
                width: 16vw !important;
                min-width: 160px;
            }
            .wrapper-conditionnement-pdf .otherpaiement .btn-other {
                width: 16vw !important;
                min-width: 160px;
            }
            .wrapper-conditionnement-pdf .otherpaiement {
                width: auto !important;
            }
            .wrapper-infos {
                padding-left: 12px;
            }
        }
        @media screen and (max-width: 960px) {
            .container-ficheproduit {
                flex-direction: column;
                align-items: center;
                height: auto !important;
            }
            .wrapper-img {
                min-height: 474px !important;
            }
            .wrapper-infos {
                width: 70% !important;
                margin-top: 24px !important;
            }
            .container-details {
                width: 96% !important;
            }
        }
        @media screen and (max-width: 770px) {
            .container-details {
                flex-direction: column;
                flex-wrap: nowrap;
            }
            .container-details div {
                width: 100% !important;
            }
        }
        @media screen and (max-width: 730px) {
            .wrapper-infos {
                width: 96% !important;
                margin-top: 24px !important;
                padding-left: 0 !important;
            }
        }
        @media screen and (max-width: 700px) {
            .wrapper-more-products {
                width: 96% !important;
                display: flex !important;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            h3 {
                font-size: 22px !important;
            }
            small {
                font-size: 19px !important;
            }
            .wrapper-btn small {
                font-size: 10px !important;
            }
        }
        @media screen and (max-width: 500px) {
            .wrapper-titleh3 {
                flex-direction: column !important;
                width: 100% !important;
                align-items: flex-start !important;
            }
            h3 {
                width: 100% !important;
                display: flex;
            }

            .wrapper-conditionnement-pdf {
                height: 200px !important;
                justify-content: center !important;
                align-items: center !important;
                margin-top: 96px !important;
            }

            .wrapper-price-quantity {
                position: relative;
                padding-right: 0 !important;
            }

            .snipcart-add-item {
                position: absolute;
                bottom: -126px !important;
                height: 46px !important;
                left: 47% !important;
                transform: translate(-50%, -50%);
            }

            .otherpaiement {
                align-self: center !important;
            }
            .conditionnement-choice {
                margin-top: 12px !important;
                align-self: center !important;
            }
        }
        @media screen and (max-width: 465px) {
            .container-details h5 {
                font-size: 19px !important;
            }
            .container-details p {
                font-size: 16px !important;
                padding: 12px 0;
            }

            .separator {
                align-self: center !important; 
                margin: 0 !important;
            }
        }
        @media screen and (max-width: 465px) {
            .wrapper-img {
                width: 100% !important;
                height: 120vw !important;
                min-width: 0 !important;
                min-height: 0 !important;
            }
            .wrapper-img img {
                height: auto !important;
                width: 85% !important;
                min-height: 0 !important;
            }
            .wrapper-title {
                flex-direction: column !important;
                align-items: flex-start !important;
            }
            .wrapper-title small {
                padding: 0 !important;
            }
        }


        h3 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }

        small {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            text-transform: capitalize;
            padding: 0 4px;
        }

        .container-ficheproduit {
            width: 79.706779%;
            display: flex;
            height: 456px;
            justify-content: space-between;
            margin-bottom: 42px;
        }

        .wrapper-img {
            height: 100%;
            width: 48.78993224%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            overflow: hidden;
            min-width: 474px;
        }
        .wrapper-titleh3 {
                flex-direction: row;
                width: 100% !important;
                display: flex;
                align-items: center;
                padding: 24px 0 48px 0;
                align-self: flex-start;
            }

        .wrapper-img img {
            max-height: 78.98903509%;
            min-height: 312px;
            width: auto;
        }
        .wrapper-infos {
            height: auto;
            width: 48.78993224%;
            display: flex;
            flex-direction: column;
            position: relative;
        }

        .wrapper-infos .logo {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
        }

        .wrapper-infos .wrapper-title {
            display: flex;
            align-items: center;
            min-height: 48px;
            width: 80%;
        }
        .wrapper-infos .wrapper-subtitle {
            display: flex;
            min-height: 48px;
        }
        .wrapper-infos .wrapper-title h1 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            display: flex;
            align-items: center;
            color: #000000;
        }
        .wrapper-infos .wrapper-title small {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            display: flex;
            align-items: center;
            color: #000000;
            padding-left: 8px;
        }
        .wrapper-infos .wrapper-subtitle h2 {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 21px;
            color: #000000;
        }

        .wrapper-infos .wrapper-excerpt {
            display: flex;
            align-items: center;
            min-height: 48px;
        }

        .wrapper-infos .wrapper-excerpt span {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
        }
        .wrapper-infos .wrapper-excerpt small  {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            text-decoration-line: underline;
            color: #000000;
            cursor: pointer;
            padding-left: 8px;
        }

        .wrapper-price {
            position: relative;
        }
        .wrapper-price-quantity .wrapper-price .price-barred {
            top: 50px;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 17px;
            text-align: center;
            text-decoration-line: line-through;
            color: #5C5C5C;
            position: absolute;

        }

        .wrapper-price-quantity {
            width: 100%;
            height: 48px;
            padding-right: 24px;
            display: flex;
            margin-top: 24px;
        }
        .wrapper-price-quantity .wrapper-price {
            height: 100%;
            width: 152px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .wrapper-price-quantity .wrapper-price span {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 36px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
        }
        .wrapper-price-quantity .wrapper-quantity {
            width: 112px;
            height: 100%;
            display: flex;
            margin-right: 24px;
        }
        .wrapper-price-quantity .wrapper-quantity button {
            width: 24px;
            height: 100%;
            background: #F4E1F4;
            border: 2px solid #F4E1F4;
            box-sizing: border-box;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            cursor: pointer;
        }
        .wrapper-price-quantity .wrapper-quantity .quantity {
            background: #FFFFFF;
            border: 2px solid #F4E1F4;
            box-sizing: border-box;
            width: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .wrapper-price-quantity .wrapper-quantity .quantity small {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #000000;
        }

        .wrapper-price-quantity .snipcart-add-item {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #FFFDF9;
            background: linear-gradient(180deg, #8F6A8F 0%, #4E1D4E 100%);
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            width: 195.45px;
            height: 100%;
            transition: 400ms;
        }

        .wrapper-price-quantity .snipcart-add-item:hover {
            transform: scale(1.06);
            transition: 400ms;
        }

        .wrapper-conditionnement-pdf {
            height: 120px;
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 24px;
        }
        .wrapper-conditionnement-pdf .otherpaiement {
            align-self: flex-end;
            width: 219px;
            height: 48px;
            display: flex;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
            position: relative;
            cursor: pointer;
        }

        .wrapper-conditionnement-pdf .otherpaiement .btn-other {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 195.45px;
            background: linear-gradient(180deg, #8F6A8F 0%, #4E1D4E 100%);
            padding: 0 24px;
        }
        .wrapper-conditionnement-pdf .otherpaiement .btn-other span {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #FFFDF9;
        }
        .wrapper-conditionnement-pdf .otherpaiement .wrapper-fleche {
            background: #F4E1F4;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 23.55px;
            padding-top: 4px;
        }

        .wrapper-conditionnement-pdf .otherpaiement .wrapper-menu-deroulant {
            position: absolute;
            width: 100%;
            height: 0px;
            top: 48px;
            background: #F4E1F4;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
            overflow: hidden;
            cursor: auto;
        }
        .wrapper-conditionnement-pdf .otherpaiement .wrapper-menu-deroulant p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            color: #000000;
            user-select: text;
        }
        .wrapper-conditionnement-pdf .otherpaiement .wrapper-menu-deroulant a {
            font-weight: bold;
            cursor: pointer;
            text-decoration: none;
            color: black;
        }

        .wrapper-conditionnement-pdf .otherpaiement:hover .wrapper-menu-deroulant {
            height: auto;
            padding: 12px;
        }

        .conditionnement-choice {
            height: 72px;
            width: 264px;
            display: flex;
            flex-direction: column;
        }
        .conditionnement-choice .wrapper-conditionnement-title {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
        }
        .conditionnement-choice .wrapper-conditionnement-title span {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
        }

        .wrapper-btn {
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 48px;
            width: 100%;
        }
        .wrapper-btn button {
            width: 112px;
            height: 100%;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
            background: #F4E1F4;
            box-sizing: border-box;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #000000;
            cursor: pointer;
        }
        .wrapper-btn small {
            font-family: Lato;
            font-style: italic;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
        }

        .wrapper-txt-livraison {
            display: flex;
            margin-top: 48px;
            align-items: center;
            div {
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin-left: 8px;
                #pickup {
                    margin-left: 4px;
                    margin-right: 0px;
                }
                a {
                    margin-right: 4px;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
        .wrapper-txt-livraison span,
        .wrapper-txt-livraison a {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            color: #000000;
            a {
                color: #4F324F;
                font-weight: 600;
                cursor: pointer;
            }
        }

        .separator {
            height: 6px;
            width: 261px;
            background: #4F324F;
            align-self: flex-start;
            margin-left: 106px;
            margin-bottom: 24px;
        }

        .container-details {
            width: 79.706779%;
            display: flex;
            height: auto;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 72px;
        }

        .container-more-products {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .wrapper-title-more {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
        }
        .wrapper-title-more h6{
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #000000;
        }

        .wrapper-more-products {
            width: 1384px;
            height: auto;
            display: grid;
            grid-gap: 48px 24px;
            ${'' /* margin-bottom: 96px; */}
            grid-template-columns: repeat(auto-fill, 328px);
            grid-template-rows: auto;
        }

`

export default StyledPageProduct